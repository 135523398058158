@import "src/assets/css/light.scss";
@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fitParent {
  flex: 1;
  z-index: 100;
  transition: all 0.3s ease-out;
}

.loader {
  position: fixed;
  background-color: #fff;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  transition: all 0.3s ease-out;
}

.loaderImage {
  width: 300px;
  height: 55px;
  animation: moveUp 0.5s ease-in-out 1.5s forwards;
}

.spinnerContainer {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out 1.5s forwards;
  transition: width 0.3s ease-in-out;
}
